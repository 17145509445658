import { oldmethod, newPcafMethod } from '@/constants/export-report';
import i18n from '@/lang/i18n';
import { PERIOD_DASHBOARD } from '@/constants/dashboard';
import { DEFAULT_YEAR } from '@/constants/registerData';
import { GICS_PULLDOWN, RATE_CURRENCY } from '@/constants/registerData';
import { formatValue, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import store from '@/store';
const dataScopeLayer = {
  scope1: {
    pattern1: {
      method: i18n.t('b_register_product.method_calculated_by_fuel_method_scope1'),
    },
    pattern2: {
      method: i18n.t('b_register_product.method_calculated_by_the_tkm_method_scope1'),
    },
    pattern3: {
      method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method_scope1'),
    },
    pattern4: {
      method: i18n.t('b_register_product.method_calculated_activities_other_than_transportation'),
    },
  },
  scope3: {
    category4: {
      pattern2: {
        method: i18n.t('b_register_product.method_how_to_use_the_shipper_responsibility_report_value'),
        sub_method: '',
      },
      pattern4: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_method_and_shipping_fee'),
      },
      pattern5: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_the_tkm_method'),
      },
      pattern6: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'), //method_calculated_by_fuel_efficiency_method
      },
      pattern7: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_fuel'),
      },
      pattern8: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_electricity'),
      },
    },
    category6: {
      pattern1: {
        method: i18n.t('b_register_product.method_calculated_from_a_passenger_kilometer'),
      },
      pattern2: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_method'),
      },
      pattern3: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_calculated_from_the_payment_amount'),
      },
      pattern5: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_accommodation'),
      },
      pattern6: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_business_trip_days'),
      },
      pattern7: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_employees'),
      },
    },
    category7: {
      pattern1: {
        method: i18n.t('b_register_product.method_calculated_from_a_passenger_kilometer'),
      },
      pattern2: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_method'),
      },
      pattern3: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_calculated_from_the_payment_amount'),
      },
      pattern5: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_employees_and_business_days'),
      },
    },
    category9: {
      pattern1: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_method_and_shipping_fee'),
      },
      pattern2: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_the_tkm_method'),
      },
      pattern3: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_fuel'),
      },
      pattern5: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_electricity'),
      },
    },
    category11: {
      pattern1: {
        method: i18n.t('b_register_product.method_calculated_from_the_direct_use_stage'),
        sub_method: i18n.t('b_register_product.method_energy_use_products'),
      },
      pattern2: {
        method: i18n.t('b_register_product.method_calculated_from_the_direct_use_stage'),
        sub_method: i18n.t('b_register_product.method_fuel_feedstock'),
      },
      pattern3: {
        method: i18n.t('b_register_product.method_calculated_from_the_direct_use_stage'),
        sub_method: i18n.t('b_register_product.method_GHG_containing_products'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_calculated_from_the_emissions_at_the_indirect_use_stage'),
      },
    },
    category15: {
      pattern1: {
        method: i18n.t('b_register_product.method_stock'),
      },
      pattern2: {
        method: i18n.t('b_register_product.method_debt_bond_loan'),
      },
      pattern3: {
        method: i18n.t('b_register_product.method_project_finance'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_asset_management_customer_service'),
      },
      pattern5: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_listed_stocks_bouds'),
      },
      pattern6: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_bussiness_loans_unlisted_stocks'),
      },
      pattern7: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_project_finance'),
      },
      pattern8: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_commercial_estate'),
      },
      pattern9: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_home_loan'),
      },
      pattern10: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_car_loan'),
      },
      pattern11: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_debt'),
      },
    },
  },
};

export const getDataLayer = (scope, category, pattern_id) => {
  let dataLayer = {};
  scope = parseInt(scope);
  category = parseInt(category);
  if (scope === 1) {
    dataLayer = dataScopeLayer['scope' + scope]?.['pattern' + pattern_id];
  } else {
    dataLayer = dataScopeLayer['scope' + scope]?.['category' + category]?.['pattern' + pattern_id];
  }

  return dataLayer;
};

export const getScopeName = (scope) => {
  if (scope <= 3) {
    return `${i18n.t('list_submited.table_scope')} ` + scope;
  } else {
    return i18n.t('register_data.hyperlink_others');
  }
};

export const getCategoryName = (scope, category) => {
  if (scope <= 3) {
    if (category === 16) {
      return i18n.t('register_data.hyperlink_others');
    }
    if (scope <= 2) {
      return '';
    }
    return `${i18n.t('status.table_category')} ` + category; //table_category
  } else {
    return '';
  }
};

export const handleResizeGrid = (theGrid, listColumn, listWidth, callBack) => {
  let paddingCell = 14;
  listColumn.forEach((column, index) => {
    let width = listWidth[index];

    if (width < column.minWidth) {
      width = column.minWidth;
      if (column.dataMap) {
        width = column.minWidth;
      }
    } else if (width > column.maxWidth) {
      width = column.maxWidth;
    }
    callBack(theGrid, index, width + paddingCell);
  });
};

export const getScopeCateriesNameFromPatternId = (scopeCategoriesList, patternId, typeClassify = {}, typeData = {}) => {
  let tx = '';

  if (typeClassify && typeData && [47, 48].includes(patternId)) {
    tx = `_${typeClassify.name}_${typeData.name} `;
  } else if (typeData) {
    tx = `_${typeData.name} `;
  }
  let dataScope = scopeCategoriesList;
  dataScope[17].methods = [...oldmethod, ...newPcafMethod];
  let itemName = '';
  dataScope.forEach((item) => {
    if (item.row_num_pattern === patternId) {
      itemName = item.text;
      return;
    } else {
      if (item?.methods?.length > 0) {
        item?.methods?.forEach((itemLayer1) => {
          if (itemLayer1.row_num_pattern === patternId) {
            itemName = item.text + '_' + itemLayer1.text + tx;
            return;
          } else {
            if (itemLayer1?.methods?.length > 0) {
              itemLayer1?.methods?.forEach((itemLayer2) => {
                if (itemLayer2.row_num_pattern === patternId) {
                  itemName = item.text + '_' + itemLayer1.text + '_' + itemLayer2.text + tx;
                  return;
                }
              });
            }
          }
        });
      }
    }
  });
  return itemName;
};

export const getDaysCustom = (moment, newYear, startMonth) => {
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;

  newYear = newYear || currentYear;
  startMonth = startMonth || currentMonth;

  const daysInMonth = moment(`${newYear}-${startMonth}`, 'YYYY-MM').daysInMonth();
  let dayList = [];
  for (let i = 1; i <= daysInMonth; i++) {
    dayList.push({
      text: i,
      value: i,
    });
  }
  return dayList;
};

export const calcYearFromStartMonthCustome = (startMonth, yearSelected = 2017, isGetNextYear = false) => {
  let yearList = [];
  let lastYear = DEFAULT_YEAR.MAX_YEAR;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const isValidStartMonth = startMonth > 1;
  if (startMonth > currentMonth) {
    lastYear = lastYear - 1;
  }
  if (isValidStartMonth || isGetNextYear) {
    lastYear += 1;
  }
  for (let i = lastYear; i >= DEFAULT_YEAR.MIN_YEAR; i--) {
    yearList.push({
      text: i18n.t('register_data.year_calendar_select', { year: i }),
      value: i,
    });
  }
  return yearList;
};

export const getMonthListCustome = (newYear, startMonth) => {
  let monthList = [];
  let maxMonth = 13;
  let minMonth = 1;
  const currentDate = new Date();
  let currentYear = DEFAULT_YEAR.MAX_YEAR;
  let currentMonth = currentDate.getMonth() + 1;
  if (startMonth > currentMonth) {
    currentYear = currentYear - 1;
  }
  if (currentYear + 1 === newYear) {
    // compare new year with currentYear
    maxMonth = startMonth;
  }

  if (newYear === DEFAULT_YEAR.MIN_YEAR) {
    minMonth = startMonth;
  }

  for (let i = minMonth; i < maxMonth; i++) {
    let month = convertMonthCalendar(i);
    monthList.push({
      text: i18n.t('register_data.month_select', { month: month }),
      value: i,
    });
  }
  return monthList;
};

export const convertMonthCalendar = (month, isNumber = false) => {
  if (i18n.locale === 'en') {
    const monthsAbbreviation = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (month >= 1 && month <= 12) {
      return monthsAbbreviation[month - 1];
    }
  }

  if (isNumber) {
    return Number(month);
  }
  return month;
};

export const getFirstMonthByOrder = (moment, startMonth, order, isHalfYear = false) => {
  let addMonth = 0;
  switch (Number(order)) {
    case 1:
      addMonth = 0;
      break;
    case 2:
      addMonth = 3;
      break;
    case 3:
      addMonth = 6;
      break;
    default:
      addMonth = 9;
      break;
  }
  if (isHalfYear) {
    switch (Number(order)) {
      case 1:
        addMonth = 0;
        break;
      case 2:
        addMonth = 6;
        break;
      default:
        break;
    }
  }
  return moment(startMonth, 'M').add(addMonth, 'months').format('M');
};
export const calculateEndDate = (date, periodType) => {
  if (!periodType) return;
  switch (periodType) {
    case PERIOD_DASHBOARD.PERIOD_YEAR:
      date.setFullYear(date.getFullYear() + 1);
      date.setMonth(date.getMonth() - 1);
      break;
    case PERIOD_DASHBOARD.PERIOD_HALF_YEAR:
      date.setMonth(date.getMonth() + 5);
      break;
    case PERIOD_DASHBOARD.PERIOD_QUARTER_YEAR:
      date.setMonth(date.getMonth() + 2);
      break;
    case PERIOD_DASHBOARD.PERIOD_MONTH_YEAR:
      date.setMonth(date.getMonth());
      break;
    default:
      throw new Error('Invalid period type');
  }
  const endMonth = date.getMonth() + 1;
  const endYear = date.getFullYear();
  return [endMonth, endYear];
};
export const getPeriodFromMonthYear = (moment, startMonth, month, year) => {
  const yearStart = startMonth > month && year - 1 > 2016 ? year - 1 : year;
  const monthStart = year - 1 > 2016 ? startMonth : '01';

  const formatDate = moment(`${yearStart}/${monthStart}`, 'YYYY/MM');

  return `${formatDate.format('YYYY/MM')} - ${formatDate.add(11, 'months').format('YYYY/MM')}`;
};

export const preparePhysicalList = (type, startMonth) => {
  let currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  if (startMonth && startMonth > currentMonth) {
    currentYear = currentYear - 1;
  }
  let dataList = [];
  for (let i = currentYear; i >= DEFAULT_YEAR.MIN_YEAR; i--) {
    if (type === 'year') {
      dataList.push({
        value: i,
        name: i18n.t('register_data.year_calendar_select', { year: i }),
      });
    }
    if (type === 'half-year') {
      dataList.push({
        value: `1-${i}`,
        name: `${i} ${i18n.t('dashboard_main.label_first_half_of_fiscal_year')}`,
      });
      dataList.push({
        value: `2-${i}`,
        name: `${i} ${i18n.t('dashboard_main.label_end_half_chart_title')}`,
      });
    } else if (type === 'quarter-year') {
      dataList.push({
        value: `1-${i}`,
        name: `${i} 1Q`,
      });
      dataList.push({
        value: `2-${i}`,
        name: `${i} 2Q`,
      });
      dataList.push({
        value: `3-${i}`,
        name: `${i} 3Q`,
      });
      dataList.push({
        value: `4-${i}`,
        name: `${i} 4Q`,
      });
    }
  }
  return dataList;
};

export const prepareBranchData = (res) => {
  let organizationalList = [];
  let company_name = [];
  let business_name = [];
  let country = [];
  let layer_3 = [];
  let layer_4 = [];
  let layer_5 = [];
  let layer_6 = [];
  let dataIdSort = [];
  let dataSort = [];
  let filterPatternList = [];
  let company_name_obj = {};
  let organizationalList_obj = {};
  let business_name_obj = {};
  let country_obj = {};
  let layer_3_obj = {};
  let layer_4_obj = {};
  let layer_5_obj = {};
  let layer_6_obj = {};
  let companyBranchIds = [];
  let organizationalObj = {};

  res.data.forEach((organizational) => {
    organizationalList.push({
      key: organizational.id,
      value: organizational.organizational_division,
      branch_ids: organizational.branch_ids,
    });
    organizationalObj[organizational.id] = organizational.organizational_division;
    organizational.child?.forEach((company) => {
      companyBranchIds.push({
        organizational_division: organizational.organizational_division,
        company_name: company.company_name?.trim(),
        branch_ids: company.branch_ids,
        organizational_division_id: organizational.id,
      });
      company?.child.forEach((business) => {
        if (business?.child === undefined) {
          dataIdSort.push(business.id);
          dataSort.push(business);
        }
        business?.child?.forEach((country) => {
          if (country?.child === undefined) {
            dataIdSort.push(country.id);
            dataSort.push(country);
          }
          country?.child?.forEach((layer3Item) => {
            if (layer3Item?.child === undefined) {
              dataIdSort.push(layer3Item.id);
              dataSort.push(layer3Item);
            }
            layer3Item?.child?.forEach((layer4Item) => {
              if (layer4Item?.child === undefined) {
                dataIdSort.push(layer4Item.id);
                dataSort.push(layer4Item);
              }
              layer4Item?.child?.forEach((layer5Item) => {
                if (layer5Item?.child === undefined) {
                  dataIdSort.push(layer5Item.id);
                  dataSort.push(layer5Item);
                }
                layer5Item?.child?.forEach((layer6Item) => {
                  if (layer6Item?.child === undefined) {
                    dataIdSort.push(layer6Item.id);
                    dataSort.push(layer6Item);
                  }
                  layer6Item?.child?.forEach((item) => {
                    dataIdSort.push(item.id);
                    dataSort.push(item);
                  });
                });
              });
            });
          });
        });
      });
    });
  });
  // sort data
  dataIdSort
    .forEach((id) => {
      dataSort.forEach((item, key) => {
        if (item.id === id) {
          dataSort.splice(key, 1);
          ['business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6'].forEach((key) => {
            item[key] = item[key] === null ? i18n.t('register_data.checkbox_blank') : item[key];
          });
          filterPatternList.push({
            ...item,
            organizational_division_value: organizationalObj[item.organizational_division],
            company_name: item.company_name?.trim(),
            business_name: item.business_name?.trim(),
            country: item.country?.trim(),
            layer_3: item.layer_3?.trim(),
            layer_4: item.layer_4?.trim(),
            layer_5: item.layer_5?.trim(),
            layer_6: item.layer_6?.trim(),
          });
          const existCompanyIndex = company_name.findIndex((company) => company.value === item.company_name);
          company_name_obj[item.company_name] = item.company_name?.trim();
          if (existCompanyIndex < 0) {
            company_name.push({
              organizational_division: item.organizational_division,
              name: item.company_name?.trim(),
              value: item.company_name?.trim(),
              status: item.status,
            });
          } else {
            if (item.status === true) {
              company_name[existCompanyIndex].status = true;
            }
          }

          if (item.business_name) {
            const existBusinessIndex = business_name.findIndex((business) => business.value === item.business_name);
            business_name_obj[item.business_name] = item.business_name?.trim();
            if (existBusinessIndex < 0) {
              business_name.push({
                key: item.business_name?.trim(),
                value: item.business_name?.trim(),
                name: item.business_name?.trim(),
                company_name: item.company_name?.trim(),
                organizational_division: item.organizational_division,
                status: item.status,
              });
            } else {
              if (item.status === true) {
                business_name[existBusinessIndex].status = true;
              }
            }
          }

          if (item.country) {
            const existCountryIndex = country.findIndex((country) => country.value === item.country);
            country_obj[item.country] = item.country?.trim();
            if (existCountryIndex < 0) {
              country.push({
                key: item.country?.trim(),
                value: item.country?.trim(),
                name: item.country?.trim(),
                organizational_division: item.organizational_division,
                company_name: item.company_name?.trim(),
                business_name: item.business_name?.trim(),
                layer3: item.layer_3?.trim(),
                layer4: item.layer_4?.trim(),
                layer5: item.layer_5?.trim(),
                layer6: item.layer_6?.trim(),
                status: item.status,
              });
            } else {
              if (item.status === true) {
                country[existCountryIndex].status = true;
              }
            }
          }

          if (item.layer_3) {
            const existLayer3Index = layer_3.findIndex((layer3) => layer3.value === item.layer_3);
            layer_3_obj[item.layer_3] = item.layer_3?.trim();
            if (existLayer3Index < 0) {
              layer_3.push({
                key: item.layer_3?.trim(),
                value: item.layer_3?.trim(),
                name: item.layer_3?.trim(),
                organizational_division: item.organizational_division,
                company_name: item.company_name?.trim(),
                business_name: item.business_name?.trim(),
                layer3: item.layer_3?.trim(),
                layer4: item.layer_4?.trim(),
                layer5: item.layer_5?.trim(),
                layer6: item.layer_6?.trim(),
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_3[existLayer3Index].status = true;
              }
            }
          }

          if (item.layer_4) {
            const existLayer4Index = layer_4.findIndex((layer4) => layer4.value === item.layer_4);
            layer_4_obj[item.layer_4] = item.layer_4?.trim();
            if (existLayer4Index < 0) {
              layer_4.push({
                key: item.layer_4?.trim(),
                value: item.layer_4?.trim(),
                name: item.layer_4?.trim(),
                organizational_division: item.organizational_division,
                company_name: item.company_name?.trim(),
                business_name: item.business_name?.trim(),
                layer3: item.layer_3?.trim(),
                layer4: item.layer_4?.trim(),
                layer5: item.layer_5?.trim(),
                layer6: item.layer_6?.trim(),
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_4[existLayer4Index].status = true;
              }
            }
          }

          if (item.layer_5) {
            const existLayer5Index = layer_5.findIndex((layer5) => layer5.value === item.layer_5);
            layer_5_obj[item.layer_5] = item.layer_5?.trim();
            if (existLayer5Index < 0) {
              layer_5.push({
                key: item.layer_5?.trim(),
                value: item.layer_5?.trim(),
                name: item.layer_5?.trim(),
                organizational_division: item.organizational_division,
                company_name: item.company_name?.trim(),
                business_name: item.business_name?.trim(),
                layer3: item.layer_3?.trim(),
                layer4: item.layer_4?.trim(),
                layer5: item.layer_5?.trim(),
                layer6: item.layer_6?.trim(),
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_5[existLayer5Index].status = true;
              }
            }
          }

          if (item.layer_6) {
            const existLayer6Index = layer_6.findIndex((layer6) => layer6.value === item.layer_6);
            layer_6_obj[item.layer_6] = item.layer_6?.trim();
            if (existLayer6Index < 0) {
              layer_6.push({
                key: item.layer_6?.trim(),
                value: item.layer_6?.trim(),
                name: item.layer_6?.trim(),
                organizational_division: item.organizational_division,
                company_name: item.company_name?.trim(),
                business_name: item.business_name?.trim(),
                layer3: item.layer_3?.trim(),
                layer4: item.layer_4?.trim(),
                layer5: item.layer_5?.trim(),
                layer6: item.layer_6?.trim(),
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_6[existLayer6Index].status = true;
              }
            }
          }
        }
      });
    });

  business_name = moveElementToLastPosition(business_name);
  country = moveElementToLastPosition(country);
  layer_3 = moveElementToLastPosition(layer_3);
  layer_4 = moveElementToLastPosition(layer_4);
  layer_5 = moveElementToLastPosition(layer_5);
  layer_6 = moveElementToLastPosition(layer_6);
  organizationalList = checkOrganizationalDivisionDisabled(organizationalList, filterPatternList);
  organizationalList.forEach((item) => {
    organizationalList_obj[item.value] = item;
  });
  const organizational_division = organizationalList;
  store.dispatch('registerData/updateListType', organizationalList, { root: true });
  // store.dispatch(
  //   'registerData/actionUpdateListBranchObj',
  //   {
  //     company_name_obj,
  //     country_obj,
  //     layer_3_obj,
  //     layer_4_obj,
  //     layer_5_obj,
  //     layer_6_obj,
  //     business_name_obj,
  //     organizationalList_obj,
  //   },
  //   { root: true },
  // );
  return {
    business_name,
    country,
    layer_3,
    layer_4,
    layer_5,
    layer_6,
    company_name,
    organizational_division,
    filterPatternList,
    companyBranchIds,
    company_name_obj,
    country_obj,
    layer_3_obj,
    layer_4_obj,
    layer_5_obj,
    layer_6_obj,
    business_name_obj,
    organizationalList_obj,
  };
};

function moveElementToLastPosition(arr) {
  const fromIndex = arr.findIndex((item) => item.key === i18n.t('register_data.checkbox_blank'));
  if (fromIndex >= 0) {
    const arrLength = arr.length;
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(arrLength - 1, 0, element);
  }
  return arr;
}

const checkOrganizationalDivisionDisabled = (organizationalList, filterPatternList) => {
  let compareOrganizationalList = [];
  organizationalList.forEach((organiDiv) => {
    const isDisabled = filterPatternList
      .filter((item) => item.organizational_division === organiDiv.key)
      .every((item) => item.status === false);
    compareOrganizationalList.push({
      key: organiDiv.key,
      value: organiDiv.value,
      status: isDisabled ? false : true,
      branch_ids: organiDiv.branch_ids,
    });
  });
  return compareOrganizationalList;
};

export const findRowNumPattern = (dataProps) => {
  const {scopeCategoriesData, scope, category, method, methodLayer2, isPCAF} = dataProps;
  if (isPCAF) {
    return newPcafMethod.find(item => item.method === method)?.row_num_pattern;
  }
  const foundItem = scopeCategoriesData.find(item => {
    return item.scope === scope && item.categories === category;
  });
  if (foundItem) {
    if (foundItem.methods) {
      for (let subItem of foundItem.methods) {
        if (subItem.method === method) {
          return subItem.row_num_pattern;
        }
        if (subItem.methods && methodLayer2) {
          for (let subSubItem of subItem.methods) {
            if (subSubItem.methodLayer2 === methodLayer2) {
              return subSubItem.row_num_pattern;
            }
          }
        }
      }
    } else {
      return foundItem.row_num_pattern;
    }
  }

  return null;
};
export const isHideColumnEmissionPcaf = (method, type_data) => {
  const { categoryIdSelect, dataTypeIdSelect } = type_data;

  const isCategoryMatch = [1, 2].includes(categoryIdSelect);
  const isDataTypeMatch = [1, 2].includes(dataTypeIdSelect);

  if ([1, 2].includes(method) && isCategoryMatch && isDataTypeMatch) {
    return true;
  }

  if (method === 3 && isDataTypeMatch) {
    return true;
  }

  return false;
};

export const isHideColValueNumber = (method, type_data) => {
  const { dataTypeIdSelect } = type_data;

  if (([4, 5].includes(method) && [7, 8].includes(dataTypeIdSelect)) || (method === 6 && dataTypeIdSelect === 12)) {
    return true;
  }

  return false;
};

export const getEmissionFactorOfGicByYearAndRate = (gicVal, year) => {
  let yearVal = year;
  if (yearVal > 2024) yearVal = 2024;
  const rate = RATE_CURRENCY[yearVal];
  const million = 1000000000;
  const gicValBigNumber = math.bignumber(formatValue(gicVal));
  const rateBigNumber = math.bignumber(formatValue(rate));
  return formatBigNumber(math.evaluate(`${gicValBigNumber.toString()} / ${rateBigNumber.toString()} / ${million}`), 50);
};

export const getListGicsByYear = () => {
  const yearList = [];
  for (let index = 2010; index < 2032; index++) {
    // get list year 2010 -> 2031
    yearList.push(index);
  }

  let gicsListObject = {};
  yearList.forEach((year) => {
    const dataOfYear = [...GICS_PULLDOWN].map((gicItem) => {
      return {
        key: gicItem.key,
        value: gicItem.value,
        autoField: getEmissionFactorOfGicByYearAndRate(gicItem.autoField, year),
      };
    });
    gicsListObject[year] = dataOfYear;
  });
  return gicsListObject;
};

export const getPatternFileImport = (params) => {
  const {
    scope,
    category,
    method,
    methodLayer2,
    isScoreSummary,
    isEmissionViewScreen,
    settingPcafBySelectMonth,
    idPattern,
    isPCAF,
  } = params;
  let patternFile = null;
  if (isScoreSummary) {
    return 'others/scoreSummary';
  }

  switch (scope) {
    case 1:
      switch (method) {
        case 1:
          patternFile = 'scope1category4';
          break;
        case 2:
          patternFile = 'scope1category1';
          break;
        case 3:
          patternFile = 'scope1category1method3';
          break;
        default:
          patternFile = 'scope1category1method2';
          break;
      }
      break;
    case 2:
      patternFile = 'scope2method1';
      break;
    case 3:
      switch (category) {
        case 1:
          patternFile = 'scope3category1';
          break;
        case 2:
          patternFile = 'scope3category2';
          break;
        case 3:
          patternFile = 'scope3category3';
          break;
        case 4:
          if (method === 1 && methodLayer2 === 4) {
            patternFile = 'scope3category4method4';
          } else if (method === 1 && methodLayer2 === 5) {
            patternFile = 'scope3category4method6';
          } else if (method === 1 && methodLayer2 === 6) {
            patternFile = 'scope3category4method5';
          } else if (method === 2 && methodLayer2 === 7) {
            patternFile = 'scope3category4method7';
          } else if (method === 2 && methodLayer2 === 8) {
            patternFile = 'scope3category4method8';
          } else if (method === 3) {
            patternFile = 'scope3category4method2';
          } else {
            patternFile = 'scope3category4method7';
          }
          break;
        case 5:
          patternFile = 'scope3category5';
          break;
        case 6:
          switch (method) {
            case 1:
              patternFile = 'scope3category6method2';
              break;
            case 2:
              patternFile = 'scope3category6method3';
              break;
            case 3:
              patternFile = 'scope3category6method1';
              break;
            case 4:
              patternFile = 'scope3category6method4';
              break;
            case 5:
              patternFile = 'scope3category6method5';
              break;
            case 6:
              patternFile = 'scope3category6method6';
              break;
            case 7:
              patternFile = 'scope3category6method7';
              break;
          }
          break;
        case 7:
          switch (method) {
            case 1:
              patternFile = 'scope3category7method4';
              break;
            case 2:
              patternFile = 'scope3category7method5';
              break;
            case 3:
              patternFile = 'scope3category7method3';
              break;
            case 4:
              patternFile = 'scope3category7method1';
              break;
            case 5:
              patternFile = 'scope3category7method2';
              break;
          }
          break;
        case 8:
          patternFile = 'scope3category8';
          break;
        case 9:
          switch (methodLayer2) {
            case 1:
              patternFile = 'scope3category9method1';
              break;
            case 2:
              patternFile = 'scope3category9method3';
              break;
            case 3:
              patternFile = 'scope3category9method2';
              break;
            case 4:
              patternFile = 'scope3category9method4';
              break;
            case 5:
              patternFile = 'scope3category9method5';
              break;
          }
          break;
        case 10:
          patternFile = 'scope3category10';
          break;
        case 11:
          if (method === 2) {
            patternFile = 'scope3category11method4';
          } else {
            if (methodLayer2 === 1) {
              patternFile = 'scope3category11method1';
            } else if (methodLayer2 === 2) {
              patternFile = 'scope3category11method2';
            } else {
              patternFile = 'scope3category11method3';
            }
          }
          break;
        case 12:
          patternFile = 'scope3category12';
          break;
        case 13:
          patternFile = 'scope3category13';
          break;
        case 14:
          patternFile = 'scope3category14';
          break;
        case 15:
          const isSettingPcafMethod = isEmissionViewScreen ? isPCAF : settingPcafBySelectMonth;
          if (isSettingPcafMethod || idPattern > 4) {
            const patternID = idPattern || method + 4;
            patternFile = 'scope3category15Pcaf/scope3category15Pcaf1';
            switch (patternID) {
              case 1:
                patternFile = 'scope3category15method1';
                break;
              case 2:
                patternFile = 'scope3category15method2';
                break;
              case 3:
                patternFile = 'scope3category15method3';
                break;
              case 4:
                patternFile = 'scope3category15method4';
                break;
              case 5:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf1';
                break;
              case 6:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf2';
                break;
              case 7:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf3';
                break;
              case 8:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf4';
                break;
              case 9:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf5';
                break;
              case 10:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf6';
                break;
              case 11:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf7';
                break;
              default:
                patternFile = 'scope3category15Pcaf/scope3category15Pcaf1';
                break;
            }
          } else {
            switch (method) {
              case 1:
                patternFile = 'scope3category15method1';
                break;
              case 2:
                patternFile = 'scope3category15method2';
                break;
              case 3:
                patternFile = 'scope3category15method3';
                break;
              case 4:
                patternFile = 'scope3category15method4';
                break;
            }
          }
          break;
        default: // cat 16
          patternFile = 'scope3category16';
          break;
      }
      break;
    default: // scope 4
      patternFile = 'scope4category1';
      break;
  }

  return patternFile;
};

export const defineMethodListLayer1 = (params) => {
  const { scope, category, settingPcafBySelectMonth } = params;
  let methodSize = 1;
  let listMethodName = [];
  let description = [];

  if (scope === 1) {
    methodSize = 4;
    listMethodName = [
      i18n.t('register_data.radio_calculated_activities_other_than_transportation'),
      i18n.t('register_data.radio_calculated_by_fuel_method_scope1'),
      i18n.t('register_data.radio_calculated_by_fuel_efficiency_method_scope1'),
      i18n.t('register_data.radio_calculated_by_the_tkm_method_scope1'),
    ];
    description = [
      i18n.t('register_data.description_activities_other_than_transportation'),
      i18n.t('register_data.description_fuel_method'),
      i18n.t('register_data.description_fuel_efficiency_method'),
      i18n.t('register_data.description_tkm_method'),
    ];
  } else if (scope === 3) {
    switch (category) {
      case 4:
        methodSize = 3;
        listMethodName = [
          i18n.t('register_data.radio_transportation'),
          i18n.t('register_data.radio_base'),
          i18n.t('register_data.radio_shipper_responsibility_report'),
        ];
        description = [
          i18n.t('register_data.description_transportation'),
          i18n.t('register_data.description_base'),
          i18n.t('register_data.description_shipper_responsibility_report'),
        ];
        break;
      case 6:
        methodSize = 7;
        listMethodName = [
          i18n.t('register_data.radio_calculated_by_fuel_method'),
          i18n.t('register_data.radio_calculated_by_fuel_efficiency_method'),
          i18n.t('register_data.radio_calculated_passenger_kilometer'),
          i18n.t('register_data.radio_calculated_payment_amount'), //s3c6 old
          i18n.t('register_data.radio_calculated_number_of_accommodation'),
          i18n.t('register_data.radio_calculated_number_business_trip_day'),
          i18n.t('register_data.radio_calculated_number_of_employees'),
        ];
        description = [
          i18n.t('register_data.description_calculated_fuel'),
          i18n.t('register_data.description_calculated_fuel_efficiency'),
          i18n.t('register_data.description_calculated_passenger_kilometer'),
          i18n.t('register_data.description_calculated_payment_amount'),
          i18n.t('register_data.description_calculated_number_of_accommodation'),
          i18n.t('register_data.description_calculated_number_business_trip_day'),
          i18n.t('register_data.description_calculated_number_of_employees'),
        ];
        break;
      case 7:
        methodSize = 5;
        listMethodName = [
          i18n.t('register_data.label_calculated_by_fuel_method'),
          i18n.t('register_data.radio_calculated_by_fuel_efficiency_method'),
          i18n.t('b_register_product.method_calculated_from_a_passenger_kilometer'),
          i18n.t('register_data.radio_calculated_payment_amount'),
          i18n.t('register_data.radio_calculated_from_the_number_of_employees_and_business_days'),
        ];
        description = [
          i18n.t('register_data.description_calculated_fuel'),
          i18n.t('register_data.description_calculated_fuel_efficiency'),
          i18n.t('register_data.description_calculated_passenger_kilometer'),
          i18n.t('register_data.description_calculated_payment_amount'),
          i18n.t('register_data.description_calculated_number_of_employees'),
        ];
        break;
      case 9:
        methodSize = 2;
        listMethodName = [i18n.t('register_data.radio_transportation'), i18n.t('register_data.radio_base')];
        description = [i18n.t('register_data.description_transportation'), i18n.t('register_data.description_base')];
        break;
      case 11:
        methodSize = 2;
        listMethodName = [
          i18n.t('register_data.radio_calculated_direct_use_stage'),
          i18n.t('register_data.radio_calculated_indirect_use_stage'),
        ];
        description = [
          i18n.t('register_data.description_calculated_direct_use_stage'),
          i18n.t('register_data.description_calculated_indirect_use_stage'),
        ];
        break;
      case 15:
        if (settingPcafBySelectMonth) {
          methodSize = 7;
          //todo: update multilange register_data.popup_method_pcaf1
          listMethodName = [
            i18n.t('register_data.popup_method_pcaf1'),
            i18n.t('register_data.popup_method_pcaf2'),
            i18n.t('register_data.popup_method_pcaf3'),
            i18n.t('register_data.popup_method_pcaf4'),
            i18n.t('register_data.popup_method_pcaf5'),
            i18n.t('register_data.popup_method_pcaf6'),
            i18n.t('register_data.popup_method_pcaf7'),
          ];
          description = [];
        } else {
          methodSize = 4;
          listMethodName = [
            i18n.t('register_data.radio_stock'),
            i18n.t('register_data.method_debt_bond_loan'),
            i18n.t('register_data.radio_project_finance'),
            i18n.t('register_data.radio_asset_management_customer_service'),
          ];
          description = [
            i18n.t('register_data.description_stock'),
            i18n.t('register_data.description_debt_bond_loan'),
            i18n.t('register_data.description_project_finance'),
            i18n.t('register_data.description_asset_management_customer_service'),
          ];
        }
        break;
      default:
        break;
    }
  }

  return { methodSize, listMethodName, description };
};

export const defineMethodListLayer2 = (params) => {
  const { scope, category, currentSelected } = params;
  let listMethodName = [];
  let description = [];
  let methodSize = 6;
  let startIndex = 1;
  if (scope !== 3) return { methodSize, startIndex, listMethodName, description };
  if (category === 4) {
    if (currentSelected === 1) {
      methodSize = 6;
      startIndex = 4;
      listMethodName = [
        i18n.t('register_data.method_calculated_by_fuel_method_and_shipping_fee'),
        i18n.t('register_data.radio_calculated_by_fuel_efficiency_method'),
        i18n.t('register_data.radio_calculated_by_the_tkm_method'),
      ];
      description = [
        i18n.t('register_data.description_fuel_method'),
        i18n.t('register_data.description_fuel_efficiency_method'),
        i18n.t('register_data.description_tkm_method'),
      ];
    } else if (currentSelected === 2) {
      methodSize = 8;
      startIndex = 7;
      listMethodName = [i18n.t('register_data.radio_fuel'), i18n.t('register_data.radio_electricity')];
      description = [i18n.t('register_data.description_fuel'), i18n.t('register_data.description_electricity')];
    }
  } else if (category === 9) {
    if (currentSelected === 1) {
      methodSize = 3;
      startIndex = 1;
      listMethodName = [
        i18n.t('register_data.radio_fuel_and_shipping'),
        i18n.t('register_data.radio_calculated_by_fuel_efficiency_method'),
        i18n.t('register_data.radio_calculated_by_the_tkm_method'),
      ];
      description = [
        i18n.t('register_data.description_fuel_and_shipping'),
        i18n.t('register_data.description_fuel_efficiency_method'),
        i18n.t('register_data.description_tkm_method'),
      ];
    } else if (currentSelected === 2) {
      methodSize = 5;
      startIndex = 4;
      listMethodName = [i18n.t('register_data.radio_fuel'), i18n.t('register_data.radio_electricity')];
      description = [i18n.t('register_data.description_fuel'), i18n.t('register_data.description_base')];
    }
  } else if (category === 11 && currentSelected === 1) {
    methodSize = 3;
    startIndex = 1;
    listMethodName = [
      i18n.t('register_data.radio_calculated_engery_using_product'),
      i18n.t('register_data.radio_calculated_fuel_feedstock'),
      i18n.t('register_data.radio_calculated_containing_product'),
    ];
    description = [
      i18n.t('register_data.description_calculated_engery_using_product'),
      i18n.t('register_data.description_calculated_fuel_feedstock'),
      i18n.t('register_data.description_calculated_containing_product'),
    ];
  }
  return { methodSize, startIndex, listMethodName, description };
};

export const handleLostFocusBasicUnitPulldown = (params) => {
  const { sender, ctx, dataProps } = params;
  if (ctx.row !== undefined && ctx.col !== undefined && sender.selectedValue) {
    dataProps.grid.setCellData(ctx.row, ctx.col, sender.selectedValue, false, true);
    const rowData = dataProps.grid.selectedItems[0].clientRowId ? dataProps.grid.selectedItems[0].clientRowId : ctx.row;
    if (!dataProps.selectedValuePulldown) return;
    dataProps.selectedValuePulldown[rowData] = {
      id: sender.selectedValue,
      source: sender.selectedItem?.source,
    };
  }
};

export const handleBasicUnitDroppedDownChanged = (params) => {
  const {sender, dataProps} = params;
  const rowData = dataProps.grid.selectedItems[0].clientRowId ? dataProps.grid.selectedItems[0].clientRowId : dataProps.grid.selection.row
  if (!dataProps.selectedValuePulldown) return
  if (sender.isDroppedDown && dataProps.selectedValuePulldown[rowData]
    && dataProps.selectedValuePulldown[rowData].id !== sender.selectedValue
  ) {
    sender.selectedValue = dataProps.selectedValuePulldown[rowData].id;
  }
}

export const handleGotFocusBasicUnitPulldown = (params) => {
  const {sender, dataProps} = params;
  const rowData = dataProps.grid.selectedItems[0].clientRowId ? dataProps.grid.selectedItems[0].clientRowId : dataProps.grid.selection.row
  if (!dataProps.selectedValuePulldown) return
  if (dataProps.selectedValuePulldown[rowData]
    && dataProps.selectedValuePulldown[rowData].id !== sender.selectedValue
  ) {
    sender.selectedValue = dataProps.selectedValuePulldown[rowData].id;
  }
};

const handleAddItemToList = (dataProps) => {
  let { item, key, list, listObj } = dataProps;
  if (item[key]) {
    const existIndex = list.findIndex((data) => data.value === item[key]);
    listObj[item[key]] = item[key];
    if (existIndex < 0) {
      list.push({
        key: item[key],
        value: item[key],
        name: item[key],
        organizational_division: item[FILTER_DATA.ORGANIZATIONAL],
        company_name: item[FILTER_DATA.COMPANY],
        business_name: item[FILTER_DATA.BUSINESS],
        country: item[FILTER_DATA.COUNTRY],
        layer3: item[FILTER_DATA.LAYER3],
        layer4: item[FILTER_DATA.LAYER4],
        layer5: item[FILTER_DATA.LAYER5],
        layer6: item[FILTER_DATA.LAYER6],
        status: item.status,
      });
    } else if (item.status === true) {
      list[existIndex].status = true;
    }
  }
};

export const setAutoFieldByBinding = (dataProps) => {
  const { binding, getListBinding = [], currentItem } = dataProps;
  if (getListBinding?.length === 0) return true;
  if (binding === 'classify_gics') {
    if (!getListBinding?.includes('emissions_factor')) {
      return true;
    } else if (currentItem['emissions_factor'] === null || currentItem['emissions_factor'] === '') {
      return true;
    }
    return false;
  }
  if (binding === 'ides_industry') {
    if (!getListBinding?.includes('emissions_factor') && !getListBinding?.includes('classify_gics')) {
      return true;
    } else if (
      (currentItem['emissions_factor'] === null || currentItem['emissions_factor'] === '') &&
      (currentItem['classify_gics'] === null || currentItem['classify_gics'] === '')
    ) {
      return true;
    }
    return false;
  }
};
